import {
    CalculatorCheckbox,
    CalculatorFieldInput,
    CalculatorFormLabel,
    CalculatorFormWrapper,
    CalculatorRadio,
    CalculatorRadioGroup,
    CalculatorRadioWrapper,
    FabricButton,
    FabricButtonContainer,
    FabricImageBox,
    FabricImageSubBox,
    FabricImageView,
    PrependAppendInput,
    PrependAppendInputGroup,
    RegularInputGroupLabel,
    TooltipPopup,
} from "./styles";
import React, { ChangeEvent, Component } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../../components/UI/search_select/search_select";
import styled, { css } from "../../theme";

import { Config } from "../../utils";
import { DraperyOrderDesignLining, highlightStyle } from "./calculator";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { DraperyModule } from "../../redux";

interface FormContainerProps {
    uiDisabled?: boolean;
}

const AdditionalFormContainer = styled.div<FormContainerProps>`
    padding: 25px 20px;
    background-color: #d7d8d3;
    width: 100%;
    margin: 30px auto 0 auto;
    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

const AdditionalFormBlock = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 15px 20px 60px;
    border-radius: 0.7em;
    margin-top: 25px;

    &:first-child {
        margin-top: 0;
    }
`;

const FabricWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FormHeader = styled.div`
    padding: 10px 0;
    border-bottom: 1px solid black;
    font-size: 20px;
    color: #338daa;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    text-transform: uppercase;
`;

const FabricDimensionInputGroup = styled(CalculatorFieldInput)`
    > div:first-child {
        width: 150px;
    }
`;

const CalculatorFabricFieldInput = styled(CalculatorFieldInput)`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
`;

const CalculatorFabricRadioGroup = styled(CalculatorRadioGroup)`
    padding-left: 0;
`;

const CalculatorFabricCheckbox = styled(CalculatorCheckbox)`
    margin-left: 0;
`;

const FabricTabFormLabel = styled.div`
    min-width: 72px;
`;

const AddPhotoLabel = styled(RegularInputGroupLabel)`
    color: #338daa;
    text-align: center;
`;

export interface Props {
    draperyOrderDesignLining: DraperyOrderDesignLining;

    targetArray: string;
    targetArrayIndex: number;
    type?: number;
    disabled: boolean;
    liningsPatternItems: any;
    workroomSetting?: WorkroomSetting;
    onSupplierSearch: SearchSelectProps["onSearch"];
    openSupplierModalClick(pathId: string, pathName: string): void;
    openImageModal(name: string, option: "add" | "delete"): void;

    openFabricsPatternModalClick(pathId: string, pathName: string): void;
    onFabricsLiningPatternSearch: SearchSelectProps["onSearch"];
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string,
        itemId?: number
    ): void;
    handleSearchSelectClear(
        pathId: string,
        pathName: string,
        key?: number
    ): void;
    highlightedSection: string | null | undefined;
    openEditFabricsPatternModalClick(
        pathName: string,
        itemId?: number | string | null
    ): void;
    workOrderEditMode: boolean;
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    isCopy: boolean;
    forBackValance?: boolean;
    orderType?: string
}

// tslint:disable-next-line:no-empty-interface
export interface FabricState {
    isLiningFromMyShop: boolean;
}

class FabricsLiningsComponent extends Component<Props, FabricState> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isLiningFromMyShop:
                (props.liningsPatternItems &&
                    props.liningsPatternItems.some(
                        (item: any) =>
                            props.draperyOrderDesignLining
                                .draperyOrderLiningsFabric &&
                            item.name ===
                                props.draperyOrderDesignLining
                                    .draperyOrderLiningsFabric.pattern
                    )) ||
                false,
        };
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        if (nextProps.draperyOrderDesignLining) {
            const isLiningFromMyShop =
                (this.props.liningsPatternItems &&
                    this.props.liningsPatternItems.some(
                        (item: any) =>
                            nextProps.draperyOrderDesignLining
                                .draperyOrderLiningsFabric &&
                            item.name ===
                                nextProps.draperyOrderDesignLining
                                    .draperyOrderLiningsFabric.pattern
                    )) ||
                false;
            this.setState({ isLiningFromMyShop });
        }
    }

    public render() {
        const {
            targetArray,
            targetArrayIndex,
            draperyOrderDesignLining,
            onSupplierSearch,
            openFabricsPatternModalClick,
            onFabricsLiningPatternSearch,
            highlightedSection,
            liningsPatternItems,
            workOrderEditMode,
            draperyOrder,
            forBackValance,
        } = this.props;

        const { draperyOrderLiningsFabric } = draperyOrderDesignLining;

        if (!draperyOrderLiningsFabric) {
            return null;
        }

        const patternId = `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.patternId`;
        const patternName = `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.pattern`;
        const img =
            liningsPatternItems &&
            liningsPatternItems.filter((item: any) => {
                return item.name === draperyOrderLiningsFabric.pattern;
            });
        const allowEdit =
            !workOrderEditMode &&
            !draperyOrderLiningsFabric.pattern &&
            draperyOrderLiningsFabric.orderOrigin === 1
                ? true
                : !workOrderEditMode && draperyOrderLiningsFabric.pattern
                ? false
                : workOrderEditMode && !draperyOrderLiningsFabric.pattern
                ? true
                : false;
        return (
            <>
                <AdditionalFormContainer uiDisabled={false}>
                    <AdditionalFormBlock>
                        <FabricWrapper className="flex flex-col lg:flex-row lg:w-full">
                            <div className="lg:w-2/3">
                                <FormHeader>
                                    {!forBackValance ? draperyOrderLiningsFabric.name : `${draperyOrderLiningsFabric.name} (for Back Valance)`}
                                </FormHeader>

                                <CalculatorFabricFieldInput
                                // style={
                                //     highlightedSection ===
                                //     `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.pattern`
                                //         ? highlightStyle
                                //         : {}
                                // }
                                >
                                    <FabricTabFormLabel>
                                        <CalculatorFormLabel>
                                            Pattern Name / #
                                        </CalculatorFormLabel>
                                    </FabricTabFormLabel>

                                    <SearchSelect
                                        name={patternName}
                                        openModalClick={() =>
                                            openFabricsPatternModalClick(
                                                patternId,
                                                patternName
                                            )
                                        }
                                        searchPage="calculator"
                                        searchType="calculator"
                                        onItemClick={(id, name, _, itemId) =>
                                            this.props.handleSearchSelectChange(
                                                id,
                                                name,
                                                patternId,
                                                patternName,
                                                itemId
                                            )
                                        }
                                        onSearch={onFabricsLiningPatternSearch}
                                        addButtonText="Add New Lining to My Shop"
                                        placeholder="Please select"
                                        keepKeyword
                                        initialValue={
                                            draperyOrderLiningsFabric.pattern ||
                                            ""
                                        }
                                        onKeywordCleared={() =>
                                            this.props.handleSearchSelectClear(
                                                patternId,
                                                patternName,
                                                targetArrayIndex
                                            )
                                        }
                                        disabled={this.props.disabled}
                                        required={true}
                                        highlightedSection={highlightedSection}
                                    />
                                </CalculatorFabricFieldInput>
                                <CalculatorFabricFieldInput
                                    style={
                                        highlightedSection ===
                                        `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`
                                            ? highlightStyle
                                            : {}
                                    }
                                >
                                    <FabricTabFormLabel>
                                        <CalculatorFormLabel>
                                            Supplier
                                        </CalculatorFormLabel>
                                    </FabricTabFormLabel>
                                    <SearchSelect
                                        name={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`}
                                        searchPage="calculator"
                                        searchItem="supplier"
                                        searchType="calculator"
                                        placeholder="Please select"
                                        keepKeyword
                                        onSearch={onSupplierSearch}
                                        openModalClick={() =>
                                            this.props.openSupplierModalClick(
                                                `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`,
                                                `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierName`
                                            )
                                        }
                                        onItemClick={async (id, name) => {
                                            this.props.handleSearchSelectChange(
                                                id,
                                                name,
                                                `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`,
                                                (await onSupplierSearch(name))
                                                    .length > 0
                                                    ? `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierName`
                                                    : `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.customSupplier`
                                            );
                                            if (
                                                (await onSupplierSearch(name))
                                                    .length === 0
                                            ) {
                                                this.props.handleSearchSelectClear(
                                                    `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`,
                                                    `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierName`,
                                                    targetArrayIndex
                                                );
                                            }
                                        }}
                                        initialValue={
                                            !draperyOrderLiningsFabric.customSupplier
                                                ? draperyOrderLiningsFabric.supplierName
                                                : draperyOrderLiningsFabric.customSupplier
                                        }
                                        onKeywordCleared={() =>
                                            this.props.handleSearchSelectClear(
                                                `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`,
                                                !draperyOrderLiningsFabric.customSupplier
                                                    ? `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.supplierName`
                                                    : `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.customSupplier`,
                                                targetArrayIndex
                                            )
                                        }
                                        disabled={
                                            allowEdit
                                                ? false
                                                : this.props.disabled ||
                                                  this.state.isLiningFromMyShop
                                        }
                                        required={true}
                                    />
                                </CalculatorFabricFieldInput>
                                <CalculatorFormLabel>
                                    Who will order?
                                </CalculatorFormLabel>
                                <CalculatorFabricRadioGroup
                                    style={
                                        highlightedSection ===
                                        `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.orderOrigin`
                                            ? highlightStyle
                                            : {}
                                    }
                                >
                                    <CalculatorRadioWrapper>
                                        <CalculatorRadio
                                            type="radio"
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.orderer-workroom`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.orderOrigin`}
                                            value="1"
                                            checked={
                                                draperyOrderLiningsFabric.orderOrigin ===
                                                    1 ||
                                                (this.props.orderType !== 'roman-shade-order' && this.props.workroomSetting &&
                                                draperyOrderLiningsFabric.orderOrigin ===
                                                    -1 &&
                                                this.props.workroomSetting
                                                    .liningsPurchaser ===
                                                    "Workroom"
                                                    ? true
                                                    : undefined)
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                        Workroom
                                    </CalculatorRadioWrapper>
                                    <CalculatorRadioWrapper>
                                        <CalculatorRadio
                                            type="radio"
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.orderer-COM`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.orderOrigin`}
                                            value="2"
                                            checked={
                                                draperyOrderLiningsFabric.orderOrigin ===
                                                    2 ||
                                                (this.props.orderType !== 'roman-shade-order' && this.props.workroomSetting &&
                                                draperyOrderLiningsFabric.orderOrigin ===
                                                    -1 &&
                                                this.props.workroomSetting
                                                    .liningsPurchaser ===
                                                    "Installer"
                                                    ? true
                                                    : undefined)
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={this.props.disabled}
                                            uiDisabled={this.props.disabled}
                                        />
                                        Client (COM)
                                    </CalculatorRadioWrapper>
                                </CalculatorFabricRadioGroup>
                                <CalculatorFormWrapper>
                                    <CalculatorFormLabel className="isSpecialtyFabricHandling">
                                        <CalculatorFabricCheckbox
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.isSpecialtyFabricHandling`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.isSpecialtyFabricHandling`}
                                            checked={
                                                !!draperyOrderLiningsFabric.isSpecialtyFabricHandling
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForBoolean
                                            }
                                            disabled={
                                                allowEdit
                                                    ? false
                                                    : this.props.disabled ||
                                                      this.state
                                                          .isLiningFromMyShop
                                            }
                                            uiDisabled={
                                                allowEdit
                                                    ? false
                                                    : this.props.disabled ||
                                                      this.state
                                                          .isLiningFromMyShop
                                            }
                                        />
                                        Specialty fabric handling
                                    </CalculatorFormLabel>
                                    <TooltipPopup
                                        text={
                                            <>
                                                This is an upcharge for fabric
                                                that is difficult to work with.
                                            </>
                                        }
                                        position="top right"
                                        className="floatRight"
                                        size="250"
                                    />
                                </CalculatorFormWrapper>
                                <FabricDimensionInputGroup
                                    style={
                                        highlightedSection ===
                                        `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.values.fabricWidth`
                                            ? highlightStyle
                                            : {}
                                    }
                                >
                                    <div>
                                        <CalculatorFormLabel>
                                            Fabric Width
                                        </CalculatorFormLabel>
                                    </div>
                                    <PrependAppendInputGroup uiSmall>
                                        <PrependAppendInput
                                            type="number"
                                            id={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.values.fabricWidth`}
                                            name={`${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.values.fabricWidth`}
                                            value={
                                                draperyOrderLiningsFabric.values
                                                    .fabricWidth !== undefined
                                                    ? draperyOrderLiningsFabric
                                                          .values.fabricWidth
                                                    : ""
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            autoComplete="off"
                                            disabled={
                                                allowEdit
                                                    ? false
                                                    : this.props.disabled ||
                                                      this.state
                                                          .isLiningFromMyShop
                                            }
                                            uiDisabled={
                                                allowEdit
                                                    ? false
                                                    : this.props.disabled ||
                                                      this.state
                                                          .isLiningFromMyShop
                                            }
                                        />
                                        <RegularInputGroupLabel>
                                            inches
                                        </RegularInputGroupLabel>
                                    </PrependAppendInputGroup>
                                </FabricDimensionInputGroup>
                            </div>

                            <div className="lg:w-1/3 lg:ml-4">
                                <FabricImageBox
                                    style={
                                        !this.props.disabled &&
                                        this.state.isLiningFromMyShop
                                            ? {
                                                  pointerEvents: "none",
                                                  opacity: 0.5,
                                              }
                                            : {}
                                    }
                                >
                                    <FabricImageSubBox>
                                        {(draperyOrder.id || this.props.isCopy) && draperyOrderLiningsFabric.imageId ? (
                                            <>
                                                <FabricImageView
                                                    src={
                                                        Config.fileStoreUrl +
                                                        (draperyOrderLiningsFabric.imageId)
                                                    }
                                                />
                                            </>
                                         ) : !draperyOrder.id && !this.props.isCopy && (img && img[0] && img[0].avatarId && img[0].avatarId.length > 0) ? <>
                                                <FabricImageView
                                                    src={
                                                        Config.fileStoreUrl +
                                                            (draperyOrderLiningsFabric.imageId ||
                                                                (img[0]
                                                                    .avatarId &&
                                                                    img[0]
                                                                        .avatarId)) ||
                                                        undefined
                                                    }
                                                />
                                            </> : !draperyOrder.id && !this.props.isCopy && draperyOrderLiningsFabric.imageId ? (
                                                <FabricImageView
                                                    src={Config.fileStoreUrl + draperyOrderLiningsFabric.imageId}
                                                />
                                            )
                                         : (
                                            <>
                                                <AddPhotoLabel>
                                                    ADD OR UPLOAD
                                                </AddPhotoLabel>
                                                <AddPhotoLabel>
                                                    YOUR OWN IMAGE HERE
                                                </AddPhotoLabel>
                                            </>
                                        )}
                                    </FabricImageSubBox>
                                </FabricImageBox>
                                <FabricButtonContainer>
                                    {(workOrderEditMode
                                        ? true
                                        : !this.props.disabled) &&
                                        (allowEdit ||
                                        !this.state.isLiningFromMyShop ? (
                                            <FabricButton
                                                type="button"
                                                onClick={() =>
                                                    this.props.openImageModal(
                                                        `${targetArray}.${targetArrayIndex}.draperyOrderLiningsFabric.imageId`,
                                                        draperyOrderLiningsFabric.imageId
                                                            ? "delete"
                                                            : "add"
                                                    )
                                                }
                                            >
                                                {draperyOrderLiningsFabric.imageId
                                                    ? "Delete Image"
                                                    : "Add Image"}
                                            </FabricButton>
                                        ) : (
                                            <FabricButton
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        draperyOrderLiningsFabric
                                                    ) {
                                                        this.props.openEditFabricsPatternModalClick(
                                                            `${targetArray}.${targetArrayIndex}`,
                                                            draperyOrderLiningsFabric.pattern
                                                        );
                                                    }
                                                }}
                                            >
                                                Edit Item in My Shop
                                            </FabricButton>
                                        ))}
                                </FabricButtonContainer>
                            </div>
                        </FabricWrapper>
                    </AdditionalFormBlock>
                </AdditionalFormContainer>
            </>
        );
    }
}

export default FabricsLiningsComponent;
