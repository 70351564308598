import React, { ChangeEvent, Component } from "react";

import { DraperyOrderDesignLining } from "./calculator";
import FabricsLiningsComponent from "./fabrics-tab-linings-component";
import { SearchSelectProps } from "../../components/UI/search_select/search_select";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { DraperyModule } from "../../redux";
export interface Props {
    liningsList: DraperyOrderDesignLining[];
    disabled: boolean;
    liningsPatternItems: any;
    workroomSetting?: WorkroomSetting;
    orderOriginState?: number;
    onSupplierSearch: SearchSelectProps["onSearch"];
    openSupplierModalClick(pathId: string, pathName: string): void;
    openImageModal(name: string, option: "add" | "delete"): void;
    openFabricsPatternModalClick(pathId: string, pathName: string): void;
    onFabricsLiningPatternSearch: SearchSelectProps["onSearch"];
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string
    ): void;
    handleSearchSelectClear(
        pathId: string,
        pathName: string,
        key?: number
    ): void;
    highlightedSection: string | null | undefined;
    openEditFabricsPatternModalClick(
        pathName: string,
        itemId?: number | null
    ): void;
    workOrderEditMode: boolean;
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    isCopy: boolean;
    forBackValance?: boolean;
    orderType?: string;
    romanShadeProps?: any;
}

// tslint:disable-next-line:no-empty-interface
export interface FabricState {}

class FabricsLiningsSection extends Component<Props, FabricState> {
    componentWillReceiveProps(
        //ovde.......
        nextProps: Readonly<Props>,
        nextContext: any
    ): void {
        if (
            nextProps.liningsList[0] &&
            nextProps.liningsList[0].draperyOrderLiningsFabric &&
            nextProps.liningsList[0].draperyOrderLiningsFabric.orderOrigin !==
                1 &&
            nextProps.liningsList[0].draperyOrderLiningsFabric &&
            nextProps.liningsList[0].draperyOrderLiningsFabric.orderOrigin !== 2
        ) {
            if (
                nextProps.liningsList[0].active &&
                nextProps.orderOriginState &&
                this.props.liningsList[0].draperyOrderLiningsFabric
            ) {
                this.props.liningsList[0].draperyOrderLiningsFabric.orderOrigin =
                    nextProps.orderOriginState;
            }
        }
        if (
            nextProps.liningsList[1] &&
            nextProps.liningsList[1].draperyOrderLiningsFabric &&
            nextProps.liningsList[1].draperyOrderLiningsFabric.orderOrigin !==
                1 &&
            nextProps.liningsList[1].draperyOrderLiningsFabric &&
            nextProps.liningsList[1].draperyOrderLiningsFabric.orderOrigin !== 2
        ) {
            if (
                nextProps.liningsList[1].active &&
                nextProps.orderOriginState &&
                this.props.liningsList[1].draperyOrderLiningsFabric
            ) {
                this.props.liningsList[1].draperyOrderLiningsFabric.orderOrigin =
                    nextProps.orderOriginState;
            }
        }
    }
    public render() {
        const {
            liningsList,
            liningsPatternItems,
            workroomSetting,
            draperyOrder,
            isCopy,
            forBackValance
        } = this.props;
        if (!liningsList || !liningsList.length) return null;

        // const arr = [
        //     {
        //         id: 0,
        //         name: "Lining",
        //         active: false,
        //         description: "",
        //         draperyOrderDesignId: null,
        //     },
        //     {
        //         id: 0,
        //         name: "Interlining",
        //         active: false,
        //         description: "",
        //         draperyOrderDesignId: null,
        //     },
        // ];

        return liningsList.map((lining, index) => {
            const { name } = lining;

            // if not forBackValance, dont change anything
            if (!forBackValance) {
                if (!liningsList[index].active) {
                    // liningsList[index] = arr[index]
                    return;
                } 
                // @ts-ignore
                if (liningsList[index] && liningsList[index].draperyOrderLiningsFabric && liningsList[index].draperyOrderLiningsFabric.isBackValance) {
                    // @ts-ignore
                    liningsList[index].draperyOrderLiningsFabric.isBackValance = false;
                }
            } else {
                // take "Lining" default option draperyOrderLiningsFabric values if creating a new order
                if (index === 0) {
                    if (!liningsList[index].draperyOrderLiningsFabric) {
                        liningsList[index].draperyOrderLiningsFabric = {
                            id: 0,
                            draperyOrderId: 0,
                            name: "LINING FABRIC",
                            type: 20,
                            supplierId: 0,
                            pattern: "",
                            imageId: null,
                            orderOrigin: -1,
                            isSpecialtyFabricHandling: false,
                            values: {},
                            supplierName: "",
                            draperyOrderSummaryComponent: {
                                id: 0,
                                item: "LINING FABRIC",
                                draperyOrderSummaryId: 0
                            },
                            isBackValance: true
                        }
                    } else {
                        if (liningsList[index] && liningsList[index].draperyOrderLiningsFabric) {
                            // @ts-ignore
                            liningsList[index].draperyOrderLiningsFabric.isBackValance = this.props.romanShadeProps.valanceAndReturns.backValance === 0 ? false : this.props.romanShadeProps.valanceAndReturns.backValance === 1 ? true : false;
                        }
                    }
                    // liningsList[index].active = true;
                } else return;
            }

            return (
                <FabricsLiningsComponent
                    key={`${name}-${index}`}
                    disabled={this.props.disabled}
                    draperyOrderDesignLining={lining}
                    targetArray="liningsList"
                    targetArrayIndex={index}
                    type={20}
                    openFabricsPatternModalClick={
                        this.props.openFabricsPatternModalClick
                    }
                    onFabricsLiningPatternSearch={
                        this.props.onFabricsLiningPatternSearch
                    }
                    onSupplierSearch={this.props.onSupplierSearch}
                    openSupplierModalClick={this.props.openSupplierModalClick}
                    openImageModal={this.props.openImageModal}
                    handleInputChangeForNumber={
                        this.props.handleInputChangeForNumber
                    }
                    handleInputChangeForString={
                        this.props.handleInputChangeForString
                    }
                    handleInputChangeForBoolean={
                        this.props.handleInputChangeForBoolean
                    }
                    handleSelectListChange={this.props.handleSelectListChange}
                    handleSearchSelectChange={
                        this.props.handleSearchSelectChange
                    }
                    handleSearchSelectClear={this.props.handleSearchSelectClear}
                    highlightedSection={this.props.highlightedSection}
                    liningsPatternItems={liningsPatternItems}
                    workroomSetting={workroomSetting}
                    openEditFabricsPatternModalClick={
                        this.props.openEditFabricsPatternModalClick
                    }
                    workOrderEditMode={this.props.workOrderEditMode}
                    draperyOrder={draperyOrder}
                    isCopy={isCopy}
                    forBackValance={forBackValance ? true : false}
                    orderType={this.props.orderType}
                />
            );
        });
    }
}

export default FabricsLiningsSection;
